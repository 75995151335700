import React from 'react';
import { useLanguage } from '../contexts/LanguageContext';

const Footer: React.FC = () => {
  const { translate } = useLanguage();

  return (
    <footer>
      <p>&copy; {new Date().getFullYear()} Llense. {translate('rights_reserved')}</p>
    </footer>
  );
};

export default Footer;
